import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import {getToken, getUser, resetUserSession} from "./sections/auth/service/auth";
import {useEffect} from "react";
import axios from "axios";
import PropTypes from "prop-types";
import LoginPage from "./pages/LoginPage";
import TrackPage from "./pages/TrackPage";
import SearchPage from "./pages/Search";
import DashboardLayout from "./layouts/dashboard";
import DashboardAppPage from "./pages/DashboardAppPage";
import ProductsPage from "./pages/ProductsPage";
import BlogPage from "./pages/BlogPage";
import SimpleLayout from "./layouts/simple";
import Page404 from "./pages/Page404";
import {Box} from "@mui/material";
import User from "./_mock/user";
import OrdersPage from "./pages/OrdersPage";
import EditOrderPage from "./pages/EditOrderPage";
import PrintersPage from "./pages/PrintersPage";
import PrintPage from "./pages/Print";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import DropboxPage from "./pages/DropboxPage";
import BulkLabelsPage from "./pages/BulkLabelsPage";
import BlanksPage from "./pages/BlanksPage";

// ----------------------------------------------------------------------

export default function App() {
    useEffect(() => {
        const token = getToken();
        if (token === 'undefined' || token === undefined || token === null || !token) {
            resetUserSession();
            return;
        }
        const config = {
            url: 'https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/verify',
            method: 'post',
            data: {
                email: getUser().email,
                token: token
            },
            headers: {
                'Content-Type': 'application/json'
            }
        }

        axios(config)
            .then((response) => {
            })
            .catch((error) => {
                resetUserSession();
            });
    }, [])
    const PrivateRoute = ({ children }) => {
        return getUser() ? children : <Navigate to="/login" />;
    }

    PrivateRoute.propTypes = {
        children: PropTypes.node.isRequired
    }
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
            <Box>
                <Routes>
                    <Route exact path="/login" element={<LoginPage />} />
                    <Route path={"/track/:id"} element={<TrackPage />} />
                    <Route path={"/blanks"} element={
                        <PrivateRoute>
                            <BlanksPage />
                        </PrivateRoute>
                    } />
                    <Route path={"/print/:id"} element={<PrintPage />} />
                    <Route path={"/search"} element={<SearchPage />} />
                    <Route path={"/dropbox"} element={
                        <PrivateRoute>
                            <DropboxPage />
                        </PrivateRoute>
                    } />
                    <Route path={"bulk-labels"} element={
                        <PrivateRoute>
                            <BulkLabelsPage />
                        </PrivateRoute>
                    } />
                    <Route path={"/blog"} element={
                        <PrivateRoute>
                            <BlogPage />
                        </PrivateRoute>} >
                    </Route>
                    <Route path={"/edit-order/:id"} element={
                        <PrivateRoute>
                            <EditOrderPage />
                        </PrivateRoute>} >
                    </Route>
                    <Route path={"/printer/:name"} element={
                        <PrivateRoute>
                            <PrintersPage />
                        </PrivateRoute>} >
                    </Route>
                    <Route path={"/user"} element={
                        <PrivateRoute>
                            <OrdersPage />
                        </PrivateRoute>} >
                    </Route>
                    <Route path={"/products"} element={
                        <PrivateRoute>
                            <ProductsPage />
                        </PrivateRoute>} >
                    </Route>
                    <Route path="/" element={
                        <PrivateRoute>
                            <BlanksPage />
                        </PrivateRoute>
                    }>
                    </Route>

                    <Route exact path="/404" element={<Page404 />}  />
                    <Route path="*" element={<Navigate to="/404" />} />
                </Routes>
            </Box>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
