import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
//import navConfig from './config';
import {getUser} from "../../../sections/auth/service/auth";

import axios from "axios";
import SvgColor from "../../../components/svg-color";
const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
// ----------------------------------------------------------------------
// Inside the Nav component, before the return statement


const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');
  const [user, setUser] = useState(null);
  const [printers, setPrinters] = useState([]);
  const staticNavConfig = [
    {
      title: 'blanks',
      path: '/',
      icon: icon('ic_cart'),
    },
    {
      title: 'Dropbox',
      path: '/dropbox',
      icon: icon('media')
    },
    {
      title: 'Labels',
      path: '/bulk-labels',
      icon: icon('barcode')
    }
  ];
  const generateNavConfig = () => {
    let navConfig = [...staticNavConfig];

    if (user && user.type === "admin") {
      // If the user is an admin, add the "unassigned" navigation item


      // Then add the printer-specific navigation items
      const printersConfig = printers.map(printer => ({
        title: printer.name, // use printer.name to get the name of the printer
        path: `/printer/${printer.name}`, // create a dynamic path based on printer name
        // add icon if needed
      }));
      //navConfig = navConfig.concat(printersConfig);
    }

    // Finally, add the "Logout" navigation item
    navConfig.push({
      title: 'Logout',
      path: '/login',
      icon: icon('ic_disabled'),
    });

    return navConfig;
  };


  useEffect(() => {
    if(user === null) {
      setUser(getUser());
    }
    const fetchPrinters = async () => {
      try {
        const response = await axios.get('https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/printer');
        setPrinters(response.data);
      } catch (error) {
        console.error("Error fetching printers:", error);
      }
    }

    fetchPrinters();
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >


      <Box sx={{ mb: 5, mx: 2, marginTop: '20px;' }}>
        <Link underline="none">
          <StyledAccount>
            <Box
                component="img"
                src="/assets/header-logo.png"
                alt="Header Logo"
                sx={{
                  display: 'inline-flex',
                  width: '12%'
                }}
            />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {user && user.name}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={generateNavConfig()} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>

      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
