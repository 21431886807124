import {
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCardImage,
    MDBCol,
    MDBContainer,
    MDBProgress,
    MDBProgressBar,
    MDBRow,
    MDBTypography,
    MDBInput,
    MDBBtn
} from "mdb-react-ui-kit";
import React, { useState, useEffect } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import {Box} from "@mui/material";
export default function SearchPage() {

    const [inputId, setInputId] = useState('');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const handleInputChange = (e) => {
        setInputId(e.target.value);
    };

    // Function to initiate search based on user input
    const handleSearch = () => {
        navigate(`/track/${inputId}?email=${email}`);
    };

    return (
        <>
            <section className="gradient-custom" style={{backgroundColor: "#eee", height: "100vh"}}>
                <MDBContainer className="py-5 h-auto">
                    <MDBRow className="justify-content-center align-items-center h-100">
                        <MDBCol lg="10" xl="8">
                            <MDBCard style={{borderRadius: "10px"}}>
                                <MDBCardHeader className="px-4 py-5">
                                    <MDBTypography tag="h5" className="text-muted mb-0">
                                        Please enter your <b>order number</b> and <b>email</b> to validate your information
                                    </MDBTypography>
                                    <Box
                                        component="img"
                                        src="/assets/header-logo.png"
                                        alt="Header Logo"
                                        sx={{
                                            position: 'fixed',
                                            top: {xs: 16, sm: 24, md: 40},
                                            left: {xs: 16, sm: 24, md: 40},
                                            width: '4%',
                                        }}
                                    />
                                </MDBCardHeader>
                                <MDBCardBody className="p-4">
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <MDBCol className="text-center">
                                            <MDBInput style={{
                                                width: "100%",
                                                height: "50px",
                                                margin: "auto"

                                            }} label="Enter Your Order Number" type="text"
                                                      onChange={handleInputChange}/>
                                            <MDBInput style={{
                                                width: "100%",
                                                height: "50px",
                                                margin: "auto",
                                                marginTop: "20px"
                                            }} label="Enter Your Email"
                                                      onChange={(e) => {
                                                            setEmail(e.target.value);
                                                      }}
                                                      type="text"/>
                                            <MDBBtn style={{
                                                marginTop: "20px",
                                                width: "80%",
                                            }} onClick={handleSearch}>Search</MDBBtn>
                                        </MDBCol>
                                    </div>


                                    <div className="d-flex justify-content-between pt-2">

                                        <p className="text-muted mb-0">

                                        </p>
                                    </div>

                                </MDBCardBody>

                                <MDBCardFooter className="border-0 px-4 py-5" style={{
                                    backgroundColor: "#f0f4f9",
                                    borderBottomLeftRadius: "10px",
                                    borderBottomRightRadius: "10px"
                                }}>

                                </MDBCardFooter>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </section>
        </>
    );
}