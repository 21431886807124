import {
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCardImage,
    MDBCol,
    MDBContainer,
    MDBProgress,
    MDBProgressBar,
    MDBRow,
    MDBTypography,
    MDBInput,
    MDBBtn
} from "mdb-react-ui-kit";
import React, { useState, useEffect, useRef } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import {Box, CircularProgress, Container} from "@mui/material";
import ReconnectingWebSocket from 'reconnecting-websocket';
import {TextField} from "@material-ui/core";
import DashboardLayout from "../layouts/dashboard";
export default function BulkLabelsPage() {

    const [inputId, setInputId] = useState('');
    const [orderIds, setOrderIds] = useState([]);
    const [error, setError] = useState('');
    const [addresses, setAddresses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [allFilesLoading, setAllFilesLoading] = useState(false);
    const [length, setLength] = useState('');
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [accessToken, setAccessToken] = useState('');
    const [shippingLabels, setShippingLabels] = useState([]);
    const ws = useRef();
    const [zipDownloadLink, setZipDownloadLink] = useState('');

    useEffect(() => {
        ws.current = new ReconnectingWebSocket('wss://tm9uol7bff.execute-api.us-east-2.amazonaws.com/production/');
        ws.current.onopen = () => {
            console.log('WebSocket Connected');
        };
        ws.current.onmessage = (evt) => {
            console.log("received data", JSON.parse(evt.data));
            const data = JSON.parse(evt.data);
            if (data.url) {
                setZipDownloadLink(data.url);
                console.log('Zip File URL Received:', data.url);
                setAllFilesLoading(false);
            }
        };
        ws.current.onclose = () => {
            console.log('WebSocket Disconnected');
        };

        return () => {
            if (ws.current) {
                ws.current.close();
            }
        };
    }, []);
    const [fromAddress, setFromAddress] = useState({
        company: "5Boys Apparel",
        name: "Hannah @ 5Boys",
        street1: "113 Jetplex Circle",
        street2: "A7",
        city: "MADISON",
        state: "Alabama",
        zip: "35758",
        country: "United States",
        phone: "256-690-9785"
    });

    useEffect(() => {

    }, []);


    const handleInputChange = (e) => {
        setInputId(e.target.value);
        const ids = e.target.value.split(' ').filter(id => id.trim() !== '');
        console.log("the ids", ids)
        setOrderIds(ids);
    };


    const handleSearch = async () => {
        try {
            setLoading(true);
            await searchFile(accessToken)
        } catch (error) {
            console.error('Error searching files:', error);
            setError('Error searching orders');

        }
    };
    const handlePrint = async () => {
        console.log("print labels");
        setLoading(true);
        let allLabelUrls = [];

        for (const address of addresses) {
            const url = "https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/shipping-label";
            const data = {
                label_type: "PNG",
                from_address: fromAddress,
                to_address: {
                    company: address.company_name,
                    name: address.customer_name,
                    street1: address.address1,
                    street2: address.address2,
                    city: address.city,
                    state: address.state,
                    zip: address.zip,
                    country: "United States",
                    phone: ""
                },
                parcels: [{
                    length: length,
                    width: width,
                    height: height,
                    weight: weight
                }]
            };
            try {
                const response = await axios.post(url, data);
                console.log("shipping response", response.data);
                response.data[0].order_number = address.order_number;
                setShippingLabels(currentLabels => [...currentLabels, response.data[0]]);
                allLabelUrls.push({ url: response.data[0].label_url, name: response.data[0].tracking_code + '.png' });

                const order = {
                    order_number: parseInt(address.order_number),
                    tracking_number: response.data[0].tracking_code,
                    public_url: response.data[0].public_url,
                    label_url: response.data[0].label_url
                }
                await updateOrder(order);

            } catch (error) {
                console.error('Error creating shipping label:', error);
                // Optionally handle the error, e.g., by showing an error message
            }
        }

        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            setAllFilesLoading(true);
            ws.current.send(JSON.stringify({
                action: 'processFiles',
                data: { "fileUrls": allLabelUrls},
            }));
            console.log('Sent label URLs to WebSocket:', allLabelUrls);
        }
        setLoading(false);
    };
    const updateOrder = async(order) => {
        const body ={
            tracking_number: order.tracking_number,
            label_url: order.label_url,
            public_url: order.public_url,
            order_number: order.order_number
        }
        await axios.post("https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/bulk-shipping-label-update", body)

    }

    const searchFile = async (Token) => {
        const headers = {
            Authorization: `Bearer ${Token}`,
            'Content-Type': 'application/json',
        };
        let allDownloadData = [];

        for (const orderId of orderIds) {
            const url = "https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/fetch-printavo-order";
            const data = {
                order_id: orderId
            }
            const response = await axios.post(url, data, {headers});
            for(const address of response.data.order.order_addresses_attributes){
                if(address.name === "Customer Shipping"){
                    address.order_number = orderId;
                    allDownloadData.push(address);
                }
            }

        }
        console.log("all download data", allDownloadData)
        setAddresses(allDownloadData);
        setLoading(false);
    };

    return (
        <DashboardLayout>
            <Container>
                <MDBContainer className="py-5 h-auto">
                    <MDBRow className="justify-content-center align-items-center h-100">
                        <MDBCol lg="10" xl="8">
                            <MDBCard style={{borderRadius: "10px"}}>
                                <MDBCardHeader className="px-4 py-5">
                                    <MDBTypography tag="h5" className="text-muted mb-0">
                                        Please enter your order number to find shipping address

                                    </MDBTypography>
                                    <Box
                                        component="img"
                                        src="/assets/header-logo.png"
                                        alt="Header Logo"
                                        sx={{
                                            position: 'fixed',
                                            top: {xs: 16, sm: 24, md: 40},
                                            left: {xs: 16, sm: 24, md: 40},
                                            width: '4%',
                                        }}
                                    />
                                </MDBCardHeader>
                                <MDBCardBody className="p-4">
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <MDBCol className="text-center">
                                            <MDBInput style={{width: "100%", height: "50px", margin: "auto"}}
                                                      label="Enter Your Order Numbers (space-separated)" type="text"
                                                      value={inputId} onChange={handleInputChange}/>
                                            <MDBBtn style={{marginTop: "20px", width: "80%"}} onClick={handleSearch}>
                                                {!loading && (
                                                    "Search"
                                                )}
                                                {loading && (
                                                    "Searching..."
                                                )}
                                            </MDBBtn>
                                        </MDBCol>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <MDBCol className="text-center">
                                            {addresses && addresses.length > 0 && (
                                                <div className="d-flex flex-column align-items-center mb-4">
                                                    {addresses.map((address, index) => (
                                                        <MDBTypography key={index} tag="h5" className="mb-0">
                                                            {address.customer_name} - {address.address1} {address.address2}, {address.city} {address.state}, {address.zip}
                                                        </MDBTypography>
                                                    ))}
                                                </div>
                                            )}
                                            {addresses && addresses.length > 0 && (
                                                <>
                                                    <Box display="flex" alignItems="center" mt={2}>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            label="Length"
                                                            style={{paddingRight: '10px'}}
                                                            onChange={(event) => setLength(event.target.value)}
                                                        />
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            label="Width"
                                                            style={{padding: '0 10px'}}
                                                            onChange={(event) => setWidth(event.target.value)}
                                                        />
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            label="Height"
                                                            style={{padding: '0 10px'}}
                                                            onChange={(event) => setHeight(event.target.value)}
                                                        />
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            label="Weight"
                                                            style={{paddingLeft: '10px'}}
                                                            onChange={(event) => setWeight(event.target.value)}
                                                        />
                                                    </Box>

                                                    <MDBBtn style={{marginTop: "20px", width: "80%"}}
                                                            onClick={handlePrint}>
                                                        Print Labels
                                                    </MDBBtn>
                                                </>
                                            )}
                                        </MDBCol>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <MDBCol className="text-center">
                                            {shippingLabels && shippingLabels.length > 0 && (
                                                <div className="d-flex flex-column align-items-center mb-4">
                                                    {shippingLabels.map((link, index) => (
                                                        <MDBBtn key={index} style={{marginTop: "10px", width: "80%"}}
                                                                href={link.label_url} target="_blank"
                                                                rel="noopener noreferrer">{link.tracking_code}</MDBBtn>
                                                    ))}
                                                </div>
                                            )}
                                        </MDBCol>

                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <MDBCol className="text-center">
                                            {allFilesLoading && (
                                                <>
                                                    <MDBTypography tag="h5" className="text-muted mb-0">
                                                        Creating Downloadable Zip File
                                                    </MDBTypography>
                                                    <CircularProgress/>
                                                </>

                                            )}
                                            {zipDownloadLink && (
                                                <MDBBtn style={{marginTop: "20px", width: "80%"}} href={zipDownloadLink}
                                                        target="_blank" rel="noopener noreferrer">
                                                    Download All Labels
                                                </MDBBtn>
                                            )}
                                        </MDBCol>
                                    </div>


                                    <div className="d-flex justify-content-between pt-2">
                                        <MDBTypography tag="h5" className="text-muted mb-0">
                                            {error && (
                                                <MDBTypography tag="h5" className="text-danger mb-0">
                                                    {error}
                                                </MDBTypography>
                                            )}

                                        </MDBTypography>
                                        <MDBCol className="text-center">
                                            {loading && (
                                                <CircularProgress/>
                                            )}
                                        </MDBCol>
                                    </div>

                                </MDBCardBody>

                                <MDBCardFooter className="border-0 px-4 py-5" style={{
                                    backgroundColor: "#f0f4f9",
                                    borderBottomLeftRadius: "10px",
                                    borderBottomRightRadius: "10px"
                                }}>

                                </MDBCardFooter>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </Container>
        </DashboardLayout>
    );
}