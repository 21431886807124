import {
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCardImage,
    MDBCol,
    MDBContainer,
    MDBProgress,
    MDBProgressBar,
    MDBRow,
    MDBTypography,
    MDBInput,
    MDBBtn
} from "mdb-react-ui-kit";
import React, { useState, useEffect, useRef } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import {Box, CircularProgress, Container} from "@mui/material";
import ReconnectingWebSocket from 'reconnecting-websocket';
import DashboardLayout from "../layouts/dashboard";
import {Helmet} from "react-helmet-async";
export default function DropboxPage() {

    const [files, setFiles] = useState([]);
    const [inputId, setInputId] = useState('');
    const [orderIds, setOrderIds] = useState(['34107']);
    const [downloadLinks, setDownloadLinks] = useState([]);
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const API_KEY = "0vyngcryfs7t59l";
    const [accessToken, setAccessToken] = useState('');
    const ws = useRef();

    useEffect(() => {
        ws.current = new ReconnectingWebSocket('wss://tm9uol7bff.execute-api.us-east-2.amazonaws.com/production/');
        ws.current.onopen = () => {
            console.log('connected');
            setIsOpen(true);
        };
        ws.current.onmessage = evt => {
            // handle the message received
            const data = JSON.parse(evt.data);
            console.log('Received:', data);
            if(data.url){
                console.log('Received:', data.url);
                setDownloadLinks([data.url]);
                setLoading(false);
            }


        };

        ws.current.onclose = () => {
            console.log('disconnected');
            setIsOpen(false);
        };
    }, []);

    useEffect(() => {
        async function fetchToken() {
            const accessToken = new URLSearchParams(window.location.hash.substring(1)).get('access_token');
            if (accessToken) {
                console.log("Access Token:", accessToken);
                setAccessToken(accessToken);
                try {
                    await axios.post(
                        'https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/dropbox-update-token',
                        { token: accessToken }
                    );
                } catch (error) {
                    console.error("Error updating token:", error);
                }
            } else {
                try {
                    const response = await axios.get('https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/dropbox-token');
                    console.log("Token:", response.data);
                    setAccessToken(response.data.token);
                } catch (error) {
                    console.error("Error fetching token:", error);
                }
            }
        }

        fetchToken();
    }, []);
    const startDropboxAuth = () => {
        const appKey = API_KEY;
        const redirectUri = encodeURIComponent("https://tracking.5boysapparel.com/dropbox");
        const authUrl = `https://www.dropbox.com/oauth2/authorize?client_id=${appKey}&response_type=token&redirect_uri=${redirectUri}`;
        window.location.href = authUrl;
    };

    const handleInputChange = (e) => {
        setInputId(e.target.value);
        const ids = e.target.value.split(' ').filter(id => id.trim() !== '');
        console.log("the ids", ids)
        setOrderIds(ids);
    };

    const fetchDownloadLink = async (fileId) => {
        try {
            const response = await axios.post('https://api.dropboxapi.com/2/files/get_temporary_link',
                { path: fileId },
                { headers: { 'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json' } }
            );
            return response.data.link;
        } catch (error) {
            console.error('Error fetching download link:', error);
            return null;
        }
    };


    const handleSearch = async () => {
        try {
            setLoading(true);
            await searchFile(accessToken)
        } catch (error) {
            if (error.response && error.response.status === 401) {

                setError('Access token expired. Getting new token...');
                setTimeout(() => {
                    startDropboxAuth();
                }, 3000);

            } else {
                console.error('Error searching files:', error);
            }
        }
    };

    const searchFile = async (Token) => {
        const headers = {
            Authorization: `Bearer ${Token}`,
            'Content-Type': 'application/json',
        };
        let allDownloadData = []; // Use a more descriptive name for the data structure

        for (const orderId of orderIds) {
            const response = await axios.post('https://api.dropboxapi.com/2/files/search_v2', {
                query: orderId,
                options: {
                    path: '',
                    filename_only: true,
                },
            }, { headers });

            // Filter results for .eps files for the current orderId
            const epsFiles = response.data.matches
                .map(match => match.metadata.metadata)
                .filter(file => file.name.endsWith('.eps'));

            // Fetch download links for the .eps files found for the current orderId
            for (const file of epsFiles) {
                const link = await fetchDownloadLink(file.id);
                if (link) {
                    allDownloadData.push({ url: link, name: file.name }); // Store both the link and the file name
                }
            }
        }

        // Assuming you want to keep track of file names separately as well
        setFiles(allDownloadData.map(file => file.name));

        if (ws.current && isOpen) {
            console.log('Sending:', allDownloadData);
            ws.current.send(JSON.stringify({
                action: 'processFiles', // Ensure your Lambda is configured to handle this action
                data: { "fileUrls": allDownloadData},
            }));
        }
    };

    return (
        <DashboardLayout>
            <Helmet>
                <title>Download From Dropbox</title>
            </Helmet>
            <Container>
                <MDBContainer className="py-5 h-auto">
                    <MDBRow className="justify-content-center align-items-center h-100">
                        <MDBCol lg="10" xl="8">
                            <MDBCard style={{borderRadius: "10px"}}>
                                <MDBCardHeader className="px-4 py-5">
                                    <MDBTypography tag="h5" className="text-muted mb-0">
                                        Please enter your order number to find your dropbox files

                                    </MDBTypography>
                                    <Box
                                        component="img"
                                        src="/assets/header-logo.png"
                                        alt="Header Logo"
                                        sx={{
                                            position: 'fixed',
                                            top: {xs: 16, sm: 24, md: 40},
                                            left: {xs: 16, sm: 24, md: 40},
                                            width: '4%',
                                        }}
                                    />
                                </MDBCardHeader>
                                <MDBCardBody className="p-4">
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <MDBCol className="text-center">
                                            <MDBInput style={{ width: "100%", height: "50px", margin: "auto" }}
                                                      label="Enter Your Order Numbers (space-separated)" type="text"
                                                      value={inputId} onChange={handleInputChange} />
                                            <MDBBtn style={{ marginTop: "20px", width: "80%" }} onClick={handleSearch}>
                                                {!loading && (
                                                    "Search"
                                                )}
                                                {loading && (
                                                    "Searching..."
                                                )}
                                            </MDBBtn>
                                        </MDBCol>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <MDBCol className="text-center">
                                            {downloadLinks && downloadLinks.length > 0 && (
                                                <div className="d-flex flex-column align-items-center mb-4">
                                                    {downloadLinks.map((link, index) => (
                                                        <MDBBtn key={index} style={{ marginTop: "10px", width: "80%" }} href={link} target="_blank" rel="noopener noreferrer">Download Zip File</MDBBtn>
                                                    ))}
                                                </div>
                                            )}
                                        </MDBCol>
                                    </div>


                                    <div className="d-flex justify-content-between pt-2">
                                        <MDBTypography tag="h5" className="text-muted mb-0">
                                            {error && (
                                                <MDBTypography tag="h5" className="text-danger mb-0">
                                                    {error}
                                                </MDBTypography>
                                            )}

                                        </MDBTypography>
                                        <MDBCol className="text-center">
                                            {loading && (
                                                <CircularProgress />
                                            )}
                                        </MDBCol>
                                    </div>

                                </MDBCardBody>

                                <MDBCardFooter className="border-0 px-4 py-5" style={{
                                    backgroundColor: "#f0f4f9",
                                    borderBottomLeftRadius: "10px",
                                    borderBottomRightRadius: "10px"
                                }}>

                                </MDBCardFooter>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </Container>
        </DashboardLayout>
    );
}