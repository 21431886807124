import PropTypes from 'prop-types';
// @mui
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
// components

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
});

// ----------------------------------------------------------------------

StyleCard.propTypes = {
    product: PropTypes.object,
};

export default function StyleCard({ product }) {
    const { img, side, name, numColors } = product;

    return (
        <Card sx={{marginBottom:"10px", marginTop:"40px"}}>
            <Box sx={{display:"flex", }}>
                <Box sx={{width:"25%", background:"repeating-conic-gradient(#8080801f 0% 25%, transparent 0% 50%) 50% / 40px 40px;", padding:"10px"}}>
                    <StyledProductImg sx={{position: "relative"}} alt={name} src={img} />
                </Box>
                <Box sx={{width:"50%", paddingLeft:"40px"}}>
                    <Typography >
                        <strong>{numColors} Colors</strong>
                    </Typography>
                    <Typography>

                        {side} : {name}
                    </Typography>
                </Box>


            </Box>
        </Card>
    );
}
