import DashboardLayout from "../layouts/dashboard";
import ShopProductCard from "../sections/@dashboard/products/ProductCard";
import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Ensure you have this import for axios
import {useNavigate, useParams} from 'react-router-dom';
import {Alert, Grid} from "@mui/material";
import { Select, MenuItem, Container, FormControl, Typography, Box, Button, Paper, InputLabel, TextField } from '@material-ui/core'
import StyleCard from "../sections/@dashboard/products/StyleCard";
import ShippingLabel from "../components/ShippingLabel";

export default function EditOrderPage() {
    const [orderDetails, setOrderDetails] = useState(null);
    const [printers, setPrinters] = useState(null);
    const [order, setOrder] = useState(null); // [order, setOrder
    const { id } = useParams();
    const [message, setMessage] = useState('')
    const [severity, setSeverity] = useState('info')
    const [openShippingLabel, setOpenShippingLabel] = useState(false);
    const [shippingLabel, setShippingLabel] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        async function fetchOrderDetails() {
            try {
                const response = await axios.post('https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/order-package', {
                    orderIds: [id],
                    email: ""
                });

                setOrderDetails(response.data);
                console.log("order details", response.data)
            } catch (error) {
                console.error("Error fetching order details:", error);
            }
        }

        async function getOrder(){
            const response = await axios.get('https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/order?id='+ id);
            setOrder(response.data);
            const shipping_response = await axios.get('https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/get-shipping-label?id='+ response.data.printavo_order);
            setShippingLabel(shipping_response.data);
            console.log("order", response.data)
            console.log("shipping label", shipping_response.data)
        }
        async function getPrinters() {
            const response = await axios.get('https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/printer');
            setPrinters(response.data);
        }

        fetchOrderDetails();
        getPrinters();
        getOrder();
    }, [id]);
    if (!orderDetails) {
        return <div>Loading...</div>;
    }
    const messageHandler = (message, severity) => {
        setMessage(message);
        setSeverity(severity);
    }
    const handlePrinterChange = (event) => {
        // Update the order with the selected printer
        setOrder(prevOrder => ({ ...prevOrder, printer: event.target.value }));
    };

    const handleWorkOrderChange = (event) => {
        // Update the order with the entered work order
        setOrder(prevOrder => ({ ...prevOrder, work_order: event.target.value }));
    };
    const updateOrder = async () => {
        try {
            const response = await axios.post('https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/order', order);
            messageHandler('Order Updated', 'success');
            setTimeout(() => {
                navigate('/')
            }, 3000)
        } catch (error) {
            console.error("Error updating the order:", error);
            // Handle error, for example show an error notification.
        }
    }
    const groupProductsById = (products) => {
        return products.reduce((acc, product) => {
            acc[product.ID] = product;
            return acc;
        }, {});
    };
    const groupSizesById = (products) => {
        const sizesMap = {};

        products.forEach(product => {
            product.Styles && product.Styles.forEach(style => {
                style.Sizes && style.Sizes.forEach(size => {
                    sizesMap[size.ID] = size; // Assuming the size object has an ID property.
                });
            });
        });

        return sizesMap;
    };
    const sizeOrder = {
        'XS': 1,
        'S': 2,
        'M': 3,
        'L': 4,
        'XL': 5,
        '2XL': 6,
    };

    const groupItemsByDesignAndStyleId = (items) => {
        return items.reduce((acc, item) => {
            if (!acc[item.DesignId]) {
                acc[item.DesignId] = {};
            }

            if (!acc[item.DesignId][item.ProductStyleId]) {
                acc[item.DesignId][item.ProductStyleId] = [];
            }

            acc[item.DesignId][item.ProductStyleId].push(item);

            return acc;
        }, {});
    };


    const { Orders, SalesDocs, DesignSummaries, Products } = orderDetails.Data;
    const { Buyer } = SalesDocs[0];
    const { ShippingAddress, Items } = Orders[0];
    const groupedByDesignAndStyle = groupItemsByDesignAndStyleId(Items);
    const productsMap = groupProductsById(Products);
    const sizesMap = groupSizesById(Products);

    return (
        <DashboardLayout>
            <Container maxWidth="lg">
                <Box mt={4} mb={6}>
                    <Typography variant="h4" gutterBottom>
                        Order Summary
                    </Typography>
                    <Paper elevation={3} style={{ padding: '16px' }}>
                        <Typography variant="h6">
                            <strong>Total Quantity:</strong> {SalesDocs[0].DisplayItemQuantity}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Buyer Name:</strong> {Buyer.DisplayName}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Email:</strong> {Buyer.Email}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            <strong>Shipping Address:</strong> {ShippingAddress.SingleLine}
                        </Typography>
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{ marginTop: '10px'}}
                            onClick={() => setOpenShippingLabel(!openShippingLabel)}
                        >Create Shipping Label</Button>
                        {openShippingLabel && (
                            <ShippingLabel ShippingAddress={ShippingAddress} DateCreated={order.DateCreated} id={order.printavo_order} email={order.Email} inksoft_id={id}></ShippingLabel>
                        )}
                    </Paper>
                </Box>
                {(shippingLabel &&
                    <Box mb={4}>
                        <Paper style={{ padding: '16px' }}>
                            {shippingLabel.shipping_label.map((label, index) => (
                                <Box key={index} mt={2} mb={2}>
                                    <Typography variant="body1">
                                        <strong>Tracking Code:</strong> {label.tracking_code}
                                    </Typography>
                                    <Box mt={1}>
                                        <Button variant="contained" color="primary" onClick={() => window.open(label.label_url, '_blank')}>
                                            View Shipping Label
                                        </Button>
                                    </Box>
                                </Box>
                            ))}
                        </Paper>
                    </Box>
                )}
                <Box mb={4}>
                    <Paper elevation={2} style={{ padding: '16px' }}>
                        <Typography  variant="h5" gutterBottom>
                            Job Details
                        </Typography>
                        <Box mt={4}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="printer-label">Assign Printer</InputLabel>
                                <Select
                                    labelId="printer-label"
                                    value={order?.printer || ""}
                                    onChange={handlePrinterChange}
                                    label="Assign Printer"
                                >
                                    <MenuItem value="">
                                        <em>---Assign Printer---</em>
                                    </MenuItem>
                                    {printers?.map(printer => (
                                        <MenuItem key={printer.email} value={printer.name}>
                                            {printer.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box mt={4}>
                            <Button variant="contained" color="primary" onClick={() => window.open(order?.work_order || "", '_blank')}>
                                View Work Order
                            </Button>
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={updateOrder}
                                style={{ marginTop: '20px', flexDirection: 'column' }}
                            >
                                Update Order
                            </Button>
                        </Box>
                        {message && <Alert sx={{marginTop:'20px;'}} severity={severity}>{message}</Alert>}
                    </Paper>
                </Box>

                <Box>
                    <Typography variant="h5" gutterBottom>
                        Design Summaries
                    </Typography>
                    {DesignSummaries && DesignSummaries.map(design => (
                        <Box key={design.DesignId} mb={3}>
                            <Paper elevation={2} style={{ padding: '16px' }}>
                                <Typography variant="h4">
                                    {design.Name}
                                </Typography>
                                {design.Canvases && design.Canvases.map(canvas => (
                                    <StyleCard product={{
                                        img: `https://5boysapparel.com/${canvas.PngRelativeUrl}`,
                                        side: canvas.ProductRegion.Side,
                                        name: canvas.ProductRegion.Name,
                                        numColors: canvas.NumColors
                                    }}/>
                                ))}


                                {groupedByDesignAndStyle[design.DesignID] &&
                                    Object.entries(groupedByDesignAndStyle[design.DesignID]).map(([styleId, itemsForStyle]) => {
                                        const product = productsMap[itemsForStyle[0].ProductId];
                                        const style = product.Styles.find(s => s.ID === parseInt(styleId, 10));

                                        return (
                                            <Box key={styleId} display="flex" alignItems="center" mb={2} mt={5}>
                                                {/* Image on the Left */}
                                                <Box flex={1} mr={2}>
                                                    <ShopProductCard
                                                        product={{
                                                            name: itemsForStyle[0].FullName,
                                                            cover: `https://5boysapparel.com${itemsForStyle[0].SidePreviews[0].DesignOnProductUrl || itemsForStyle[0].SidePreviews[0].ProductOnlyUrl}`,
                                                        }}
                                                    />
                                                </Box>

                                                {/* Details on the Right */}
                                                <Box flex={2}>
                                                    <Typography variant="h4">
                                                        {product.Name} + {style.Name}
                                                    </Typography>

                                                    {itemsForStyle.sort((a, b) => sizeOrder[sizesMap[a.ProductStyleSizeId].Name] - sizeOrder[sizesMap[b.ProductStyleSizeId].Name])
                                                        .map(item => (
                                                            <Typography>
                                                                {sizesMap[item.ProductStyleSizeId].Name}: {item.Quantity}
                                                            </Typography>
                                                        ))}
                                                </Box>
                                            </Box>
                                        );
                                    })
                                }

                            </Paper>
                        </Box>
                    ))}
                </Box>
            </Container>
        </DashboardLayout>

    )
}