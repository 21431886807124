import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {useEffect, useState} from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import DashboardLayout from "../layouts/dashboard";
import axios from "axios";
import {useNavigate, useParams} from 'react-router-dom';
import {getUser} from "../sections/auth/service/auth";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'OrderID', label: 'Order ID', alignRight: false },
    { id: 'PrintavoInvoice', label: 'Printavo Invoice #', alignRight: false },
    { id: 'CustomerName', label: 'Customer Name', alignRight: false },
    { id: 'garmentType', label: 'Garment Type', alignRight: false },
    { id: 'ship_to_state', label: 'Ship to State', alignRight: false },
    { id: 'PrinterAssigned', label: 'Printer Assigned', alignRight: false },
    { id: 'FrontInkColors', label: 'Front Ink Colors', alignRight: false },
    { id: 'BackInkColors', label: 'Back Ink Colors', alignRight: false },
    { id: 'total_units', label: 'Total Units', alignRight: false },
    { id: 'customer_due_at', label: 'Due Date ', alignRight: false },
    { id: 'shipping_method', label: 'Rushed Order ', alignRight: false },
    { id: 'SL', label: 'Has SL', alignRight: false },
    { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function PrintersPage() {
    const [open, setOpen] = useState(null);
    const { name } = useParams();
    const [page, setPage] = useState(0);
    const [dateFilter, setDateFilter] = useState('week');
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [paginationToken, setPaginationToken] = useState(null);
    const [totalOrders, setTotalOrders] = useState(0);
    const [assignStatus, setAssignStatus] = useState("");
    const navigate = useNavigate();
    const user = getUser();

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orders, setOrders] = useState([]);
    useEffect(() => {
        setOrders([]);
        setPage(0);

        fetchOrders();
    }, [name, dateFilter]); // Added dateFilter dependency

    async function fetchOrders(searchQuery) {
        try {

            let requestData = dateFilter !== 'all' ? { limit: 10, printer: name, dateFilter } : { limit: 10, printer: name };

            if (paginationToken) {
                requestData.paginationToken = paginationToken;
            }
            if (searchQuery) {
                requestData.query = searchQuery;
                setPage(0);
            }
            const response = await axios.post('https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/orders', requestData);

            if (response.status === 200) {
                setOrders(response.data.orders);
                setTotalOrders(response.data.totalOrders);
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    }
    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();

        return `${month}-${day}-${year}`;
    };
    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id, dateCreated) => {
        // Find index of the object based on ID and DateCreated
        const selectedIndex = selected.findIndex(
            (order) => order.ID === id && order.DateCreated === dateCreated
        );

        let newSelected = [...selected]; // Create a shallow copy of the selected array

        if (selectedIndex === -1) {
            // If the order isn't in the array yet, add it
            newSelected.push({ ID: id, DateCreated: dateCreated });
        } else {
            // If the order is already in the array, remove it
            newSelected.splice(selectedIndex, 1);
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setPaginationToken(null);
        setRowsPerPage(parseInt(event.target.value, 10));
        fetchOrders();
    };
    const handlePrinterChange = async (printerName) => {
        setAssignStatus("Assigning...")
        console.log("printer name: ", printerName);
        console.log("selected: ", selected);
        const requestData = { printer: printerName, orders: selected };
        const response = await axios.post('https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/bulk-assign-printer', requestData);
        if (response.status === 200) {
            setAssignStatus("Assigned");
            fetchOrders();
        }
        setSelected([]);
        setAssignStatus("");
    }

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;
    const filteredOrders = applySortFilter(orders, getComparator(order, orderBy), filterName);
    const isNotFound = !filteredOrders.length && !!filterName;

    return (
        <DashboardLayout>
            <Helmet>
                <title>All Orders</title>
            </Helmet>


            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Orders
                    </Typography>
                    {dateFilter && <Typography variant="h6">{`Showing orders from the last ${sentenceCase(dateFilter)}`}</Typography>}
                    <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                        New Order
                    </Button>
                </Stack>

                <Card>
                    <UserListToolbar assignStatus={assignStatus} handlePrinterChange={handlePrinterChange} fetchOrders={fetchOrders} numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                    <Container sx={{marginBottom:'20px'}}>
                        <Button
                            variant="outlined"
                            onClick={() => { setDateFilter('week'); fetchOrders(); }}
                            style={dateFilter === 'week' ? { backgroundColor: '#f5f5f5' } : {}}
                        >
                            Last Week
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => { setDateFilter('biweek'); fetchOrders(); }}
                            style={dateFilter === 'biweek' ? { backgroundColor: '#f5f5f5' } : {}}
                        >
                            Last 15 Days
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => { setDateFilter('month'); fetchOrders(); }}
                            style={dateFilter === 'month' ? { backgroundColor: '#f5f5f5' } : {}}
                        >
                            Last Month
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => { setDateFilter('all'); fetchOrders(); }}
                            style={dateFilter === 'all' ? { backgroundColor: '#f5f5f5' } : {}}
                        >
                            All Orders
                        </Button>
                    </Container>


                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={orders.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order) => {
                                        const { ID: OrderID, printavo_visual_id: PrintavoInvoice, Customer: Name, printer: PrinterAssigned, garment_type: garmentType, ship_to_state,front_colors: FrontInkColors, back_colors: BackInkColors, total_units, customer_due_at ,shipping_method, has_sl } = order;
                                        const isSelected = selected.some(item => item.ID === OrderID && item.DateCreated === order.DateCreated);

                                        return (
                                            <TableRow hover key={OrderID} tabIndex={-1} role="checkbox" selected={isSelected}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={isSelected} onChange={(event) => handleClick(event, OrderID, order.DateCreated)} />
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    {OrderID}
                                                </TableCell>
                                                <TableCell>{PrintavoInvoice}</TableCell>
                                                <TableCell>{Name}</TableCell>
                                                <TableCell>{garmentType}</TableCell>
                                                <TableCell>{ship_to_state}</TableCell>
                                                <TableCell>{PrinterAssigned}</TableCell>
                                                <TableCell>{FrontInkColors}</TableCell>
                                                <TableCell>{BackInkColors}</TableCell>
                                                <TableCell>{total_units}</TableCell>
                                                <TableCell>{formatDate(customer_due_at)}</TableCell>
                                                <TableCell>
                                                    {shipping_method ? (shipping_method === "Free UPS Ground Shipping" ? "No" : "Yes") : ""}
                                                </TableCell>
                                                <TableCell>{has_sl ? "Yes" : "No"}</TableCell>
                                                <TableCell align="right">
                                                    <IconButton onClick={() => navigate(`/edit-order/${OrderID}`)}>
                                                        <Iconify  icon="eva:edit-2-outline" />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}

                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (53) * emptyRows
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>


                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalOrders}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>

                <MenuItem sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover>
        </DashboardLayout>
    );
}