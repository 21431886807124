import {Box, Button, Select, TextField, Typography} from "@material-ui/core";
import React, {useState} from "react";
import axios from "axios";
import {MenuItem} from "@mui/material";


export default function ShippingLabel(props){
    const [shippingAddress, setShippingAddress] = useState(props.ShippingAddress);
    const [OrderId, setOrderId] = useState(props.id);
    const [email, setEmail] = useState(props.email);
    const [DateCreated, setDateCreated] = useState(props.DateCreated);
    const [boxes, setBoxes] = useState([{ Length: "", Width: "", Height: "", Weight: "" }]);
    const [shipmentResults, setShipmentResults] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [labelType, setLabelType] = useState('PNG');
    const [fromAddress, setFromAddress] = useState({
        Company: "5Boys Apparel",
        Name: "Hannah @ 5Boys",
        Address1: "113 Jetplex Circle",
        Address2: "A7",
        City: "MADISON",
        State: "Alabama",
        Zip: "35758",
        Country: "United States",
        Phone: "256-690-9785"
    });
    const handleBoxChange = (index, key) => (event) => {
        const newBoxes = [...boxes];
        newBoxes[index][key] = event.target.value;
        setBoxes(newBoxes);
    };
    const handleInputChange = (setState, key) => (event) => {
        setState(prevState => ({ ...prevState, [key]: event.target.value }));
    };
    const addNewBox = () => {
        setBoxes([...boxes, { Length: "", Width: "", Height: "", Weight: "" }]);
    };
    async function sendShipmentData(shippingDetails) {
        const endpointURL = 'https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/shipping-label';
        const notificationURL = "https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/shipping-notification";

        try {
            shippingDetails.label_type = labelType;
            const response = await axios.post(endpointURL, shippingDetails);
            const results = response.data;

            // Handle the results as needed
            results.forEach((result, index) => {
                console.log(`Box ${index + 1} - Label URL: ${result.label_url}`);
                console.log(`Box ${index + 1} - Tracking Code: ${result.tracking_code}`);
            });

            await axios.post(notificationURL, {
                email: email,
                order_id: OrderId,
                shipping_label: results,
                date_created: DateCreated,
                inksoft_id: props.inksoft_id
            });
            return results;
        } catch (error) {
            console.error('Error sending shipment data:', error);
            throw error; // Or handle the error in another way if needed
        }
    }
    async function handleCreateLabel() {
        setIsSubmitting(true);
        const shipmentDetails = {
            from_address: {
                street1: fromAddress.Address1,
                street2: fromAddress.Address2 || '',
                city: fromAddress.City,
                state: fromAddress.State,
                zip: fromAddress.Zip,
                country: fromAddress.Country,
                company: fromAddress.Company,
                phone: fromAddress.Phone,
            },
            to_address: {
                name: `${shippingAddress.FirstName} ${shippingAddress.LastName}`,
                street1: shippingAddress.Street1,
                street2: shippingAddress.Street2 || '',
                city: shippingAddress.City,
                state: shippingAddress.State,
                zip: shippingAddress.PostCode,
                country: shippingAddress.Country,
                phone: shippingAddress.Phone,
            },
            parcels: boxes.map(box => ({
                length: box.Length,
                width: box.Width,
                height: box.Height,
                weight: box.Weight,
            }))
        };

        // Send the shipment details
        const results = await sendShipmentData(shipmentDetails);
        setShipmentResults(results);
    }

    return(
        <Box mt={4}>
            <Typography style={{paddingBottom:"20px"}}>From Address:</Typography>
            <Box>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Company"
                    value={fromAddress.Company}
                    onChange={handleInputChange(setFromAddress, "Company")}
                    style={{paddingBottom:"20px"}}
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Name"
                    value={fromAddress.Name}
                    style={{paddingBottom:"20px"}}
                    onChange={handleInputChange(setFromAddress, "Name")}
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Address 1"
                    value={fromAddress.Address1}
                    style={{paddingBottom:"20px"}}
                    onChange={handleInputChange(setFromAddress, "Address1")}
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Address 2"
                    value={fromAddress.Address2}
                    style={{paddingBottom:"20px"}}
                    onChange={handleInputChange(setFromAddress, "Address2")}
                />
            </Box>
            <Box display="flex" alignItems="center">
                <TextField
                    fullWidth
                    variant="outlined"
                    label="City"
                    value={fromAddress.City}
                    style={{paddingRight: '10px'}}
                    onChange={handleInputChange(setFromAddress, "City")}
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    label="State"
                    value={fromAddress.State}
                    style={{padding: '0 10px'}}
                    onChange={handleInputChange(setFromAddress, "State")}
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Zip"
                    value={fromAddress.Zip}
                    style={{ paddingLeft: '10px'}}
                    onChange={handleInputChange(setFromAddress, "Zip")}
                />
            </Box>
            <Box mt={2} display="flex" alignItems="center">
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Country"
                    value={fromAddress.Country}
                    style={{paddingBottom:"20px", paddingRight: '10px'}}
                    onChange={handleInputChange(setFromAddress, "Country")}
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Phone"
                    value={fromAddress.Phone}
                    style={{paddingBottom:"20px", paddingLeft: '10px'}}
                    onChange={handleInputChange(setFromAddress, "Phone")}
                />
            </Box>
            <Box mt={4}>
                <Typography style={{paddingBottom:"20px"}}>To Address:</Typography>
                <Box>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Company"
                        value={shippingAddress.CompanyName}
                        style={{paddingBottom:"20px"}}
                        onChange={handleInputChange(setShippingAddress, "CompanyName")}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Name"
                        value={`${shippingAddress.FirstName} ${shippingAddress.LastName}`}
                        style={{paddingBottom:"20px"}}
                        onChange={event => {
                            const [firstName, lastName] = event.target.value.split(' ');
                            setShippingAddress(prev => ({ ...prev, FirstName: firstName, LastName: lastName }));
                        }}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Address 1"
                        value={shippingAddress.Street1}
                        style={{paddingBottom:"20px"}}
                        onChange={handleInputChange(setShippingAddress, "Street1")}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Address 2"
                        value={shippingAddress.Street2}
                        style={{paddingBottom:"20px"}}
                        onChange={handleInputChange(setShippingAddress, "Street2")}
                    />
                </Box>
                <Box display="flex" alignItems="center">
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="City"
                        value={shippingAddress.City}
                        style={{ paddingRight: '10px'}}
                        onChange={handleInputChange(setShippingAddress, "City")}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="State"
                        value={shippingAddress.State}
                        style={{ padding: '0 10px'}}
                        onChange={handleInputChange(setShippingAddress, "State")}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Zip"
                        value={shippingAddress.PostCode}
                        style={{ paddingLeft: '10px'}}
                        onChange={handleInputChange(setShippingAddress, "PostCode")}
                    />
                </Box>
                <Box mt={2} display="flex" alignItems="center" mb={4}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Country"
                        value={shippingAddress.Country}
                        style={{paddingRight: '10px'}}
                        onChange={handleInputChange(setShippingAddress, "Country")}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Phone"
                        value={shippingAddress.Phone}
                        style={{ paddingLeft: '10px'}}
                        onChange={handleInputChange(setShippingAddress, "Phone")}
                    />
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                <Typography style={{paddingBottom:"20px"}}>Package Specifications:</Typography>
                <Button variant="contained" color="primary" onClick={addNewBox}>Add New Box</Button>
            </Box>
            <Box>
                <Typography>Label Format</Typography>
                <Box mt={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => { setLabelType('PNG'); }}
                        style={labelType === 'PNG' ? { backgroundColor: '#f5f5f5', color:'black', marginRight:'10px' } : {marginRight:'10px'}}
                    >
                        PNG
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => { setLabelType('PDF'); }}
                        style={labelType === 'PDF' ? { backgroundColor: '#f5f5f5', color:'black', marginRight:'10px' } : {marginRight:'10px'}}
                    >
                        PDF
                    </Button>
                    <Button

                        variant="contained"
                        color="primary"
                        onClick={() => { setLabelType('ZPL'); }}
                        style={labelType === 'ZPL' ? { backgroundColor: '#f5f5f5', color:'black', marginRight:'10px' } : {marginRight:'10px'}}
                    >
                        ZPL
                    </Button>
                </Box>



            </Box>
            {boxes.map((box, index) => (
                <Box key={index} display="flex" alignItems="center" mt={2}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Length"
                        value={box.Length}
                        style={{ paddingRight: '10px'}}
                        onChange={handleBoxChange(index, "Length")}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Width"
                        value={box.Width}
                        style={{padding: '0 10px'}}
                        onChange={handleBoxChange(index, "Width")}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Height"
                        value={box.Height}
                        style={{padding: '0 10px'}}
                        onChange={handleBoxChange(index, "Height")}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Weight"
                        value={box.Weight}
                        style={{paddingLeft: '10px'}}
                        onChange={handleBoxChange(index, "Weight")}
                    />
                </Box>
            ))}
            <Box mt={4} display="flex" justifyContent="center">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateLabel}
                    disabled={isSubmitting}
                >
                    Create Label
                </Button>
            </Box>
            <Box mt={4}>
                {shipmentResults && shipmentResults.map((result, index) => (
                    <Box key={index} mt={2}>
                        <Typography>Box {index + 1}:</Typography>
                        <a href={result.label_url} target="_blank" rel="noopener noreferrer">
                            View Label
                        </a>
                        <Typography>Tracking Code: {result.tracking_code}</Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    )
}