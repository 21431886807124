import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import {Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment, Box} from '@mui/material';
// component
import Iconify from '../../../components/iconify';
import React, {useEffect, useState} from "react";
import axios from "axios";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function UserListToolbar({ numSelected, filterName: propFilterName, fetchOrders, handlePrinterChange, assignStatus }) {
  const [searchValue, setSearchValue] = useState(propFilterName || "");
  const [printers, setPrinters] = useState(null);
  const [printerValue, setPrinterValue] = useState("");

  async function getPrinters() {
    const response = await axios.get('https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/printer');
    setPrinters(response.data);
  }
  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      fetchOrders(searchValue);
    }
  }
  useEffect(() => {
    getPrinters();

  }, []);

  return (
      <StyledRoot
          sx={{
            ...(numSelected > 0 && {
              color: 'primary.main',
              bgcolor: 'primary.lighter',
            }),
          }}
      >
        {numSelected > 0 ? (
            <Box display="flex" alignItems="center">
              <Box mr={2}>
                <Typography component="div" variant="subtitle1">
                  {numSelected} selected
                </Typography>
              </Box>

              <Box flex={1} sx={{width:"420px"}}>
                <FormControl fullWidth variant="outlined" sx={{ minWidth: '420px' }}>
                  <InputLabel id="printer-label">Assign Printer</InputLabel>
                  <Select
                      labelId="printer-label"
                      onChange={(e) => {
                        setPrinterValue(e.target.value);
                        handlePrinterChange(e.target.value);
                      }}
                      label="Assign Printer"
                      value={printerValue}
                  >
                    <MenuItem value="">
                      <em>---Assign Printer---</em>
                    </MenuItem>
                    {printers?.map(printer => (
                        <MenuItem key={printer.email} value={printer.name}>
                          {printer.name}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box >
                {assignStatus && (
                    <Typography component="div" variant="subtitle1">
                        {assignStatus}
                    </Typography>
                )}
              </Box>
            </Box>
        ) : (
            <StyledSearch
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Search user..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
            />
        )}

        <Tooltip title={numSelected > 0 ? "Delete" : "Filter list"}>
          <IconButton>
            <Iconify icon={numSelected > 0 ? "eva:trash-2-fill" : "ic:round-filter-list"} />
          </IconButton>
        </Tooltip>
      </StyledRoot>
  );
}
