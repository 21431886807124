import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// @mui
import {Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Alert} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import {setUserData} from "../service/auth";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('')
    const [severity, setSeverity] = useState('info')
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const messageHandler = (message, severity) => {
        setMessage(message);
        setSeverity(severity);
    }
  const handleClick = () => {
      setIsLoading(true);
      const config = {
          url: 'https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/login',
          method: 'post',
          data: {
              email: email,
              password: password
          },
          headers: {
              'x-api-key': '6eShxNuQqT7kthZeRwSzj2gfOhpMcOQG1sEaOZ7u',
              'Content-Type': 'application/json'
          }
      }
      axios(config)
          .then((response) => {
              messageHandler('Login successful', 'success');
              setUserData(response.data);
              setTimeout(() => {
                  navigate('/')
              }, 3000)
          })
          .catch((error) => {
              if (error.response.status === 401 || error.response.status === 403) {
                  messageHandler('Invalid username or password', 'error');
              } else {
                  messageHandler(error.response.data.message, 'error');
              }
          });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" onChange={(event) => setEmail(event.target.value)}/>
        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={(event) => setPassword(event.target.value)}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack>

        <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleClick}
            loading={isLoading}  // <-- here's the magic
        >
            Login
        </LoadingButton>
        {message && <Alert sx={{marginTop:'20px;'}} severity={severity}>{message}</Alert>}
    </>
  );
}
