import {
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCardImage,
    MDBCol,
    MDBContainer,
    MDBRow,
    MDBTypography,
} from "mdb-react-ui-kit";
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Box, TextField, Button } from "@mui/material";

export default function TrackPage() {
    const [orderDetails, setOrderDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [printavoOrderId, setPrintavoOrderId] = useState(null);
    const [inksoftOrderId, setInksoftOrderId] = useState(null);
    const [orderStatus, setOrderStatus] = useState(null);
    const [ArtworkApprovalDate, setArtworkApprovalDate] = useState(null);
    const [groupedByDesignAndStyle, setGroupedByDesignAndStyle] = useState({});
    const [productsMap, setProductsMap] = useState({});
    const [shippingDetails, setShippingDetails] = useState(null);
    const [publicUrl, setPublicUrl] = useState(null);
    const [customerEmail, setCustomerEmail] = useState(null);
    const [email, setEmail] = useState('');
    const [inputEmail, setInputEmail] = useState('');
    const [emailMatched, setEmailMatched] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const emailParam = queryParams.get('email');
        setEmail(emailParam || '');

        async function fetchOrderDetails() {
            try {
                const response = await axios.post('https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/order-package', {
                    orderIds: [id],
                    email: emailParam || ""
                });
                setInksoftOrderId(id);
                setOrderDetails(response.data);
                console.log("order details", response.data);
                setCustomerEmail(response.data.order.customer.email);
                checkEmailMatch(emailParam, response.data.order.customer.email);
            } catch (error) {
                if (error.response.status === 500) {
                    console.log("trying to fetch printavo order");
                    const response = await axios.post('https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/fetch-printavo-order', {
                        order_id: id
                    });
                    setPrintavoOrderId(id);
                    setInksoftOrderId(response.data.order_number);
                    setArtworkApprovalDate(response.data.approvalRequests.data.invoice.approvalRequests.edges[0].node.timestamps.createdAt);
                    console.log("printavo approval requests", response.data.approvalRequests.data.invoice.approvalRequests.edges[0].node.timestamps.createdAt);
                    console.log("printavo order response", response.data);
                    setCustomerEmail(response.data.order.customer.email);
                    const printavo_response = await axios.post('https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/order-package', {
                        orderIds: [response.data.order_number],
                        email: emailParam || ""
                    });
                    console.log("order package response", printavo_response.data);

                    setOrderDetails(printavo_response.data);

                    // get shipping details
                    const shipping_response = await axios.get(`https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/get-shipping-label?id=${id}`);
                    console.log("shipping response", shipping_response.data);
                    setShippingDetails(shipping_response.data);
                    checkEmailMatch(emailParam, response.data.order.customer.email);
                }
            }
            setLoading(false);
        }

        async function getOrder() {
            console.log("start get order status");
            const order_status_response = await axios.post('https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/printavo-order-status', {
                order_id: id
            });
            console.log("order status response", order_status_response);
            setOrderStatus(order_status_response.data.name);
            setPublicUrl(order_status_response.data.public_url);
            console.log("order status", order_status_response.data.name);
            console.log("public url", order_status_response.data.public_url);
        }

        fetchOrderDetails();
        getOrder();
    }, [id, location.search]);

    useEffect(() => {
        if (orderDetails) {
            // Assuming orderDetails.Data is available and contains Orders and Products
            const { Items } = Orders?.[0] ?? {};
            const { Products } = orderDetails?.Data ?? {};

            // Safely call the functions if Items and Products are available
            if (Items && Products) {
                const grouped = groupItemsByDesignAndStyleId(Items);
                const productsMapResult = groupProductsById(Products);
                setGroupedByDesignAndStyle(grouped);
                setProductsMap(productsMapResult);
            }
        }
    }, [orderDetails]);

    const checkEmailMatch = (emailParam, customerEmail) => {
        if (emailParam && emailParam === customerEmail) {
            setEmailMatched(true);
        } else {
            setEmailMatched(false);
        }
    };

    const handleEmailSubmit = () => {
        checkEmailMatch(inputEmail, customerEmail);
    };

    const groupProductsById = (products) => {
        return products.reduce((acc, product) => {
            acc[product.ID] = product;
            return acc;
        }, {});
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const groupItemsByDesignAndStyleId = (items) => {
        return items.reduce((acc, item) => {
            if (!acc[item.DesignId]) {
                acc[item.DesignId] = {};
            }

            if (!acc[item.DesignId][item.ProductStyleId]) {
                acc[item.DesignId][item.ProductStyleId] = [];
            }

            acc[item.DesignId][item.ProductStyleId].push(item);

            return acc;
        }, {});
    };

    const isTodayOrPast = (date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Normalize today to start of day
        const comparisonDate = new Date(date);
        comparisonDate.setHours(0, 0, 0, 0); // Normalize comparison date to start of day
        return comparisonDate <= today;
    };

    const addDays = (date, days) => {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    const { Orders, SalesDocs, DesignSummaries, Products } = orderDetails?.Data ?? {};
    const { Buyer } = SalesDocs?.[0] ?? {};
    const { TotalAmount, Items, DateCreated, Payments, EstimatedShipDate } = Orders?.[0] ?? {};
    const adjustDate = isTodayOrPast(EstimatedShipDate) && !["Order has been picked up", "Order has shipped."].includes(orderStatus);
    const finalEstimatedDate = adjustDate ? addDays(new Date(), 3) : EstimatedShipDate;

    const getProgressPercentage = (status) => {
        switch (status) {
            case "Order in production":
                return 25;
            case "Order has been printed. Tracking will be available in 6-18 hours.":
                return 75;
            case "Order has shipped.":
                return 100;
            default:
                return 5; // Default value for unknown or initial status
        }
    };

    return (
        <>
            <section className="gradient-custom" style={{ backgroundColor: "#eee", height: "100vh" }}>
                <MDBContainer className="py-5 h-auto">
                    <MDBRow className="justify-content-center align-items-center h-100">
                        <MDBCol lg="10" xl="8">
                            <MDBCard style={{ borderRadius: "10px" }}>
                                <MDBCardHeader className="px-4 py-5">
                                    {!loading && emailMatched && (
                                        <MDBTypography tag="h5" className="text-muted mb-0">
                                            {Buyer && (
                                                <>
                                                    Order Status: <p className="fw-bolder" style={{ display: "inline" }}>{orderStatus}</p>
                                                </>
                                            )}
                                        </MDBTypography>
                                    )}
                                    <Box
                                        component="img"
                                        src="/assets/header-logo.png"
                                        alt="Header Logo"
                                        sx={{
                                            position: 'fixed',
                                            top: { xs: 16, sm: 24, md: 40 },
                                            left: { xs: 16, sm: 24, md: 40 },
                                            width: '4%',
                                        }}
                                    />
                                </MDBCardHeader>
                                {loading && (
                                    <MDBCardBody className="p-4">
                                        <MDBTypography tag="h5" className="text-muted mb-0">
                                            Loading your order details...
                                        </MDBTypography>
                                    </MDBCardBody>
                                )}
                                {!loading && !emailMatched && (
                                    <MDBCardBody className="p-4">
                                        <MDBTypography tag="h5" className="text-muted mb-0">
                                            Your email doesn't match the email from the order. Please try again.
                                        </MDBTypography>
                                        <TextField
                                            label="Enter your email"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={inputEmail}
                                            onChange={(e) => setInputEmail(e.target.value)}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleEmailSubmit}
                                        >
                                            Submit
                                        </Button>
                                    </MDBCardBody>
                                )}
                                {orderDetails && emailMatched && (
                                    <MDBCardBody className="p-4">
                                        <div className="d-flex justify-content-between align-items-center mb-4">
                                            <p className="lead fw-normal mb-0">
                                                Receipt
                                            </p>
                                            <p className="small text-muted mb-0">
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    window.open(publicUrl || "", '_blank');
                                                }}>Full Invoice Details</a>
                                            </p>
                                        </div>
                                        {DesignSummaries && DesignSummaries.map(design => (
                                            groupedByDesignAndStyle[design.DesignID] &&
                                            Object.entries(groupedByDesignAndStyle[design.DesignID]).map(([styleId, itemsForStyle]) => {
                                                const product = productsMap[itemsForStyle[0].ProductId];
                                                const style = product.Styles.find(s => s.ID === parseInt(styleId, 10));
                                                return (
                                                    <MDBCard className="shadow-0 border mb-4" key={styleId}>
                                                        <MDBCardBody>
                                                            <MDBRow>
                                                                <MDBCol md="2">
                                                                    <MDBCardImage
                                                                        src={`https://5boysapparel.com${itemsForStyle[0].SidePreviews[0].DesignOnProductUrl || itemsForStyle[0].SidePreviews[0].ProductOnlyUrl}`}
                                                                        fluid
                                                                    />
                                                                </MDBCol>
                                                                <MDBCol
                                                                    md="6"
                                                                    className="text-center d-flex justify-content-center align-items-center"
                                                                >
                                                                    <p className="text-muted mb-0">{product.Name}</p>
                                                                </MDBCol>
                                                                <MDBCol
                                                                    md="2"
                                                                    className="text-center d-flex justify-content-center align-items-center"
                                                                >
                                                                    <p className="text-muted mb-0 small">Qty: {SalesDocs[0].DisplayItemQuantity}</p>
                                                                </MDBCol>
                                                                <MDBCol
                                                                    md="2"
                                                                    className="text-center d-flex justify-content-center align-items-center"
                                                                >
                                                                    <p className="text-muted mb-0 small">${TotalAmount}</p>
                                                                </MDBCol>
                                                            </MDBRow>
                                                        </MDBCardBody>
                                                    </MDBCard>
                                                );
                                            })
                                        ))}
                                        <div className="d-flex justify-content-between pt-2">
                                            <p className="fw-bold mb-0">Order Details</p>
                                            <p className="text-muted mb-0">
                                                <span className="fw-bold me-4">Total</span> ${TotalAmount}
                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-between pt-2">
                                            <p className="text-muted mb-0 ">
                                                Invoice Number: <p className="fw-bold" style={{ display: "inline" }}>{printavoOrderId}</p>
                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-between pt-2">
                                            <p className="text-muted mb-0 ">
                                                Online Order Number: <p className="fw-bold" style={{ display: "inline" }}>{inksoftOrderId}</p>
                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-between pt-2">
                                            <p className="text-muted mb-0 ">
                                                Order Placed: <p className="fw-bold" style={{ display: "inline" }}>{formatDate(DateCreated)}</p>
                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-between pt-2">
                                            <p className="text-muted mb-0 ">
                                                Artwork Approval Date: <p className="fw-bold" style={{ display: "inline" }}>{formatDate(ArtworkApprovalDate)}</p>
                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-between pt-2">
                                            <p className="text-muted mb-0 ">
                                                ESTIMATED ship date: <p className="fw-bold" style={{ display: "inline" }}>{formatDate(finalEstimatedDate)}</p>
                                            </p>
                                        </div>
                                        {shippingDetails && (
                                            <>
                                                <div className="d-flex justify-content-between pt-2">
                                                    <p className="text-muted mb-0 ">
                                                        Tracking Number: <p className="fw-bold" style={{ display: "inline" }}>{shippingDetails.tracking_number}</p>
                                                    </p>
                                                </div>
                                                <div className="d-flex justify-content-between pt-2">
                                                    <p className="text-muted mb-0 ">
                                                        Track your package: <a href="#" onClick={(e) => {
                                                        e.preventDefault();
                                                        window.open(shippingDetails.public_url || "", '_blank');
                                                    }}>Track your package</a>
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                    </MDBCardBody>
                                )}
                                <MDBCardFooter className="border-0 px-4 py-5" style={{
                                    backgroundColor: "#f0f4f9",
                                    borderBottomLeftRadius: "10px",
                                    borderBottomRightRadius: "10px"
                                }}>
                                    <MDBTypography
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                            alignItems: "center",
                                        }}
                                        className="text-uppercase mb-0">
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            window.open("https://5boysapparel.com/5boys_apparel/shop/account/login?ReturnUrl=%2Fshop%2Fhome", '_blank');
                                        }}>My Account</a>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            window.open("https://5boysapparel.com/5boys_apparel/shop/account/login?ReturnUrl=%2Fshop%2Fhome", '_blank');
                                        }}>Request Update</a>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            window.open("https://5boysapparel.com/5boys_apparel/shop/account/login?ReturnUrl=%2Fshop%2Fhome", '_blank');
                                        }}>Reorder</a>
                                    </MDBTypography>
                                </MDBCardFooter>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </section>
        </>
    );
}