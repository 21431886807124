import {
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCardImage,
    MDBCol,
    MDBContainer,
    MDBProgress,
    MDBProgressBar,
    MDBRow,
    MDBTypography,
    MDBInput,
    MDBBtn, MDBCardTitle, MDBCardText
} from "mdb-react-ui-kit";
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {Box, CircularProgress, Container} from "@mui/material";
import DashboardLayout from "../layouts/dashboard";
import {Helmet} from "react-helmet-async";
export default function BlanksPage() {

    const [inputId, setInputId] = useState('');
    const [orderIds, setOrderIds] = useState([]);
    const [error, setError] = useState('');
    const [addresses, setAddresses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [orderNumber, setOrderNumber] = useState('');
    const [warehouse, setWarehouse] = useState('');
    const [errorDetails, setErrorDetails] = useState('');
    const [RejectLineErrors, setRejectLineErrors] = useState(true);

    useEffect(() => {

    }, []);


    const handleInputChange = (e) => {
        setInputId(e.target.value);
        const ids = e.target.value.split(' ').filter(id => id.trim() !== '');
        console.log("the ids", ids)
        setOrderIds(ids);
    };


    const handleSearch = async () => {
        try {
            setLoading(true);
            await searchCustomer()
        } catch (error) {
            console.error('Error searching files:', error);
            setError('Error searching orders');

        }
    };

    const searchCustomer = async () => {
        setRejectLineErrors(true);
        setError('');
        setErrorDetails('');
        setOrderNumber('');
        setWarehouse('');
        console.log("refreshing warehouse")
        for (const orderId of orderIds) {
            const response = await axios.post('https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/fetch-printavo-order', {
                order_id: orderId
            });
            console.log("response", response.data.order.lineitems_attributes)

            const items = await axios.post('https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/ssa-skus',{
                items: response.data.order.lineitems_attributes
            })
            setItems(items.data);
            console.log("items", items.data)
            setLoading(false);
        }

    };
    const confirmOrder = async () => {
        setLoading(true);
        try {
            console.log("reject line errors", RejectLineErrors)
            const order_status = await axios.post('https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/ssa-order', {
                poNumber: orderIds[0],
                RejectLineErrors: RejectLineErrors,
                items: items
            });

            if(RejectLineErrors === false){
                console.log("order status with reject line errors false", order_status.data.order.orders[0].orderNumber)
                console.log("order status", order_status.data)
                setOrderNumber(order_status.data.order.orders[0].orderNumber)
                setWarehouse(order_status.data.order.orders[0].warehouseAbbr)
            }else{
                console.log("order number", order_status.data.order[0].orderNumber)
                console.log("order status", order_status.data)
                console.log("warehouse", order_status.data.order[0].warehouseAbbr)
                setOrderNumber(order_status.data.order[0].orderNumber)
                setWarehouse(order_status.data.order[0].warehouseAbbr)
            }


            setError('');
            setErrorDetails('');
            setRejectLineErrors(true);
        }catch (e) {
            setError("Error creating order")
            console.log("error", e.response.data.details)
            setErrorDetails(e.response.data.details)
            setRejectLineErrors(false);
        }


        setLoading(false);
    }

    return (
        <DashboardLayout>
            <Helmet>
                <title>Order Blanks</title>
            </Helmet>
            <Container>
                <MDBContainer className="py-5 h-auto">
                    <MDBRow className="justify-content-center align-items-center h-100">
                        <MDBCol lg="10" xl="8">
                            <MDBCard style={{borderRadius: "10px"}}>
                                <MDBCardHeader className="px-4 py-5">
                                    <MDBTypography tag="h5" className="text-muted mb-0">
                                        Please enter order number to order blanks

                                    </MDBTypography>
                                    <Box
                                        component="img"
                                        src="/assets/header-logo.png"
                                        alt="Header Logo"
                                        sx={{
                                            position: 'fixed',
                                            top: {xs: 16, sm: 24, md: 40},
                                            left: {xs: 16, sm: 24, md: 40},
                                            width: '4%',
                                        }}
                                    />
                                </MDBCardHeader>
                                <MDBCardBody className="p-4">
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <MDBCol className="text-center">
                                            <MDBInput style={{width: "100%", height: "50px", margin: "auto"}}
                                                      label="Enter Your Order Numbers (space-separated)" type="text"
                                                      value={inputId} onChange={handleInputChange}/>
                                            <MDBBtn style={{marginTop: "20px", width: "80%"}} onClick={handleSearch}>
                                                {!loading && (
                                                    "Search"
                                                )}
                                                {loading && (
                                                    "Searching..."
                                                )}
                                            </MDBBtn>
                                        </MDBCol>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <MDBCol className="text-center">


                                        </MDBCol>
                                    </div>

                                    <div className="d-flex justify-content-between pt-2">
                                        <MDBTypography tag="h5" className="text-muted mb-0">
                                            {error && (
                                                <>
                                                    <MDBTypography tag="h5" className="text-danger mb-0">
                                                        {error}
                                                    </MDBTypography>
                                                </>
                                            )}
                                            {errorDetails && (
                                                <div>
                                                    <MDBCol className="text-center">
                                                        <table className="table">
                                                            <thead>
                                                            <tr>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {errorDetails.map((error, index) => (
                                                                <tr key={index}>
                                                                    <td>{error.message}</td>

                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>

                                                    </MDBCol>

                                                </div>
                                            )}

                                        </MDBTypography>
                                        <MDBCol className="text-center">
                                            {loading && (
                                                <CircularProgress/>
                                            )}
                                        </MDBCol>
                                    </div>

                                    {items.length > 0 && (
                                        <>

                                            <div className="d-flex flex-column align-items-center mb-4"><MDBBtn
                                                onClick={confirmOrder}
                                                style={{width: "80%"}}
                                            >
                                                {RejectLineErrors && (
                                                    <>Confirm Order</>
                                                )}
                                                {!RejectLineErrors && (
                                                    <>Place order anyway</>
                                                )}
                                            </MDBBtn>
                                            </div>
                                            {orderNumber && (
                                                <MDBTypography tag="h5" className="my-4 mt-4">
                                                    Order Created Successfully #{orderNumber}
                                                </MDBTypography>
                                            )}
                                            {warehouse && (
                                                <MDBTypography tag="h5" className="my-4 mt-4">
                                                    Warehouse at {warehouse}
                                                </MDBTypography>
                                            )}
                                            <MDBTypography tag="h5" className="my-4">
                                                Products to be ordered:
                                            </MDBTypography>
                                        </>

                                    )}

                                    {items.length > 0 && (
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>SKU</th>
                                                <th>Style Name</th>
                                                <th>Color</th>
                                                <th>Size</th>
                                                <th>Quantity</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {items.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.sku}</td>
                                                    <td>{item.styleName}</td>
                                                    <td>{item.colorName}</td>
                                                    <td>{item.sizeName}</td>
                                                    <td>{item.qty}</td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td colSpan="4" style={{textAlign: "right"}}>Total Quantity</td>
                                                <td>{items.reduce((total, item) => total + item.qty, 0)}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    )}


                                </MDBCardBody>

                                <MDBCardFooter className="border-0 px-4 py-5" style={{
                                    backgroundColor: "#f0f4f9",
                                    borderBottomLeftRadius: "10px",
                                    borderBottomRightRadius: "10px"
                                }}>

                                </MDBCardFooter>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </Container>
        </DashboardLayout>
    );
}