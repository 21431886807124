
import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Ensure you have this import for axios
import {useNavigate, useParams} from 'react-router-dom';
import { Document, Page } from 'react-pdf';

import {  Container, Typography, Box,  Paper } from '@material-ui/core'

export default function PrintPage() {
    const { id } = useParams();
    const [publicUrl, setPublicUrl] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);
    useEffect(() => {

        async function getOrder(){

            console.log("start get order status")
            const order_status_response = await axios.post('https://tjd9bqb9al.execute-api.us-east-2.amazonaws.com/prod/printavo-order-status', {
                order_id: id
            });
            setPublicUrl(order_status_response.data.work_order)
            console.log("order status response", order_status_response.data)
            console.log("public url", order_status_response.data.work_order)
            console.log("starting to generate PDF")
            const response = await axios.post('https://api.5boysapparel.com/generate-pdf', {
                url: order_status_response.data.work_order
            }, {
                responseType: 'arraybuffer' // Handling binary data
            });

            const pdfData = new Blob([response.data], { type: 'application/pdf' });
            const pdf_url = URL.createObjectURL(pdfData);
            console.log("pdf url", pdf_url)
            setPdfFile(pdf_url);

        }


        getOrder();
    }, [id]);
    if (!publicUrl) {
        return <div>Loading...</div>;
    }


    return (
        <>
            <Container maxWidth="lg">
                <Box mt={4} mb={6}>
                    <Typography variant="h4" gutterBottom>
                        Order Summary
                    </Typography>
                    <Paper elevation={3} style={{ padding: '16px' }}>
                        {pdfFile && (
                            <iframe src={pdfFile} style={{ width: '100%', height: '600px' }} />
                        )}

                    </Paper>
                </Box>
            </Container>
        </>

    )
}